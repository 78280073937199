@tailwind base;
@tailwind components;
@tailwind utilities;

.rotate-100 {
  --tw-rotate: 100deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.max-w-screen-md {
  max-width: 28rem; /* Adjust the maximum width as needed */
}

.animate-bounce-x {
  animation: bounceHorizontal 1s infinite;
}

@keyframes bounceHorizontal {
  0%, 100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}


/*section {
  position: relative;
}*/
.section-timeline {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  height: 100vh;

}
.test{
  padding-top: 200px;
}
.timeline-container {
  height: 100%;
  width: 100%;
  position: absolute;
/*   transition: 0.6s cubic-bezier(0.28, 0, 0.18, 1); */
  padding-top: 100px;

  padding-bottom: 100px;

}
.timeline-container article {
  position: relative;
    z-index: 1000;
    color: white;
    text-align: center;

    display: flex;
flex-direction: row ;
flex-wrap: nowrap ;
justify-content: center;
align-items: center;
align-content: center;

}
.timeline-container  article.year hgroup{
/*  position: absolute;
    top: 50%;
    width: 90%;
    left: 50%;
        transform: translateY(-50%) translateX(-50%);*/
}
.timeline-container article.year {
/*   transition: 0.6s cubic-bezier(0.28, 0, 0.18, 1) 0.3s; */
  height: 100vh;
  max-height: 3rem;
  position: relative;
  color: white;
  text-align: center;
}

.timeline-container article.year hgroup h2 {
/*   transition: 0.6s cubic-bezier(0.28, 0, 0.18, 1); */
  line-height: 1;
  margin: 0;
}

.timeline-container article.year hgroup p {
  line-height: 1.5;
  font-size: 1.25rem;
  opacity: 0;
  max-height: 0px;
  max-width: 600px;
  margin: 0 auto;
/*   transition: opacity 0.6s cubic-bezier(0.28, 0, 0.18, 1); */
}

/*ACTIVE*/
#section-timeline article.year.active {
  max-height: 80vh;
}
/*#section-timeline article.year:after{
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 4px;
  background: #ffffff;
  height: 120px;
  margin-top: -60px;
  max-height: 0px;
      transform: translateX(-50%) translateY(-100%);
      transition: 0.3s cubic-bezier(0.28, 0, 0.18, 1);

}*/
#section-timeline article.year hgroup:after{
  content: '';
  position: absolute;
  /*top: 100%;*/
  top: 90%;
  left: 50%;
  width: 4px;
  background: #ffffff;
  height: 120px;

  max-height: 0px;
      transform: translateX(-50%) translateY(-100%);
/*       transition: 0.3s cubic-bezier(0.28, 0, 0.18, 1);
 */
}
#section-timeline article.year.active hgroup:after{
/*   transition: 0.6s cubic-bezier(0.28, 0, 0.18, 1) 0.6s; */
  max-height: 90px;
}

.timeline-container  article.year.active hgroup p {
  opacity: 1 !important;
  max-height: 100%;
}





.animated-svg {
  width: 100%;
  max-width: 500px; /* Adjust max-width as needed */
}

.animated-path {
  stroke-dasharray: 1000; /* Length of the path */
  stroke-dashoffset: 1000; /* Length of the path */
  animation: draw 2s linear forwards; /* Adjust animation duration (5s in this case) */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}



@keyframes spin{
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.logo:nth-child(2) {
  color: red;
}

.logo.animating {
  span {
  display: inline-block;
  pointer-events: none;

    &.spin{
      animation: spin 0.5s 1;
    }
  }

}
